import { getRequest,postRequest,postFormReq,deleteRequest } from "@/http/request_service.js";
import { API } from "@/api/api.js"

// 獲取訊息列表
export const getPushList=(params)=>{
    return getRequest(API.pushList,params);
}
// 訊息詳情
export const getPushDetails=(params)=>{
    return getRequest(API.pushList+'/'+params);
}
// 訊息刪除
export const deletePush=(params)=>{
    return deleteRequest(API.pushList+'/'+params);
}
// 獲取選擇列表
export const getSelectData=(params)=>{
    return getRequest(API.bannerSelectData,params);
}
// 編輯bnner
export const pushEdit=(params)=>{
    return postRequest(API.pushEdit,params);
}
// 上傳圖片
export const uploadImage=(params)=>{
    return postFormReq(API.uploadImage,params);
}
// 上傳文件
export const uploadFile=(params)=>{
    return postFormReq(API.uploadLimitMember,params);
}