<template>
  <div class="mainwrap pushlist" v-loading="loading">
    <div class="title">
      通知訊息
      <div class="addbtn" v-if="permissionType === 2">
        <el-button type="success" @click="toAddInfo">新增</el-button>
      </div>
    </div>

    <div class="searchform">
      <el-form
        label-width="100px"
        :inline="true"
        :model="searchForm"
        class="demo-form-inline"
      >
        <el-form-item label="標題：">
          <el-input v-model="searchForm.Title" placeholder="標題"></el-input>
        </el-form-item>
        <el-form-item label="推送時間：">
          <el-date-picker
            :editable="false"
            value-format="yyyy-MM-dd"
            v-model="searchForm.StartTime"
            type="date"
            placeholder="請選擇時間"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询</el-button>
          <el-button @click="reSet">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="tablewrap">
      <el-table :data="tableData" style="width: 100%" stripe>
        <el-table-column prop="title" label="標題"> </el-table-column>
        <el-table-column prop="pushType" label="推送類型"> </el-table-column>
        <el-table-column prop="startTime" label="推送時間"> </el-table-column>
        <el-table-column prop="isLimit" label="是否限定會員"> </el-table-column>
        <el-table-column prop="status" label="狀態"> </el-table-column>
        <el-table-column label="操作" width="220" v-if="permissionType === 2">
          <template slot-scope="scope">
            <el-button @click="handleEdit(scope.row)" type="primary" plain :disabled="scope.row.status==='已發送'">
              编辑
            </el-button>
            <div class="delbtn">
              <el-popconfirm
              confirm-button-text='確定'
              cancel-button-text='取消'
              confirm-button-type="danger"
              icon="el-icon-info"
              icon-color="red"
              title="確定刪除？"
              @confirm="delPush(scope.row.id)"
            >
                <el-button type="danger" slot="reference" :disabled="scope.row.status==='已發送'">刪除</el-button>
              </el-popconfirm>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="pagination">
      <el-pagination
        background
        :hide-on-single-page="isSinglePage"
        layout="prev, pager, next,jumper"
        @current-change="handleCurrentChange"
        :page-size="pageSize"
        :current-page="currentPage"
        :total="tableDataTotal"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as api from "./api";
import { getStore} from '@/utils/storage';
export default {
  name:'pushList',
  data() {
    return {
      loading:false,
      searchForm: {
        Title:"",
        StartTime:""
      },
      currentPage:1,
      pageSize:10,
      tableData: [],
      tableDataTotal:0,
      isSinglePage:false,
      permissionType: -1
    };
  },
  created(){
    this.getPushList();
    this.permissionType = parseInt(getStore("permissionType"));
  },
  activated(){
    this.getPushList();
  },
  methods: {
    toAddInfo() {
      this.$router.push({
        path: "/cms/pushedit",
        query: {
          type: "create",
        },
      });
    },
    handleEdit(item) {
      this.$router.push({
        path: "/cms/pushedit",
        query: {
          type: "edit",
          id: item.id,
        },
      });
    },
    // 搜索
    onSearch() {
      this.currentPage=1;
      this.getPushList();
    },
    // 獲取列表
    getPushList(){
      this.loading=true;
      api.getPushList({
        ...this.searchForm,
        SkipCount:(this.currentPage-1)*this.pageSize,
        MaxResultCount:this.pageSize
      }).then(res=>{
        if(res.systemCode===200){
          this.tableData=res.data.items;
          this.tableDataTotal=res.data.totalCount;
        }
        this.loading=false;
      })
    },
    // 翻頁
    handleCurrentChange(val) {
      this.currentPage=val;
      this.getPushList();
    },
    // 重置
    reSet(){
      this.currentPage=1;
      this.searchForm={
        Title:"",
        StartTime:""
      }
      this.getPushList();
    },
    // 刪除
    delPush(id) {
      //如果刪除項为當前頁最后一项
      this.loading=true;
      api.deletePush(id).then(res=>{
        if(res.systemCode===200){
          this.$message.success('刪除成功');
          if (this.tableData.length <= 1) {
            this.currentPage = this.currentPage > 1 ? this.currentPage - 1 : 1;
          }
          this.getPushList();
        }
      })
    },
  },
  filters:{

  }
};
</script>

<style lang="scss" scoped>
.pushlist {
  .searchform {
    padding: 20px;
    border-bottom: 1px solid #ccc;
  }
  .addbtn {
    width: 100px;
    float: right;
  }
  .delbtn{
    display: inline-block;
    margin-left: 10px;
  }
}
</style>
<style lang="scss">
  // .table_content {
  //   max-height: 24px;
  //   max-width: 250px;
  //   white-space: nowrap;
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  //   p {
  //     max-width: 250px;
  //     white-space: nowrap;
  //     overflow: hidden;
  //     text-overflow: ellipsis;
  //   }
  // }
</style>
